import { useContext } from "react";

import { ViewerContext } from "~/contexts/ViewerContext";

export default function useCurrentViewer() {
  const context = useContext(ViewerContext);
  return {
    viewer: context.viewer,
    mutate: context.reloadCurrentAuthViewer,
    isLoading: context.isLoading,
    online: context.online,
    previousPath: context.previousPath,
  };
}
