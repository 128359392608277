/* eslint-disable no-shadow */
import _ from "lodash";
import useSWR, { SWRConfiguration } from "swr";

import {
  SimpleListVASPInput,
  UserVASPListInput,
  DocumentRequest,
  DocumentRequestInput,
  TfUploadVASPDocumentResponse,
  DDQDocument,
  VaspSettingsPayload,
  SaveVaspSettingsInput,
  VaspnetSearchResponse,
  VaspTopCounterPartiesResponse,
  VASPDueDiligengeReviewPayload,
  VASPDueDiligengeReviewResponse,
  PatchEntityEnablementParams,
  PatchManageSubsidiariesParams,
  VaspActionItems,
  UserPreferenceResponse,
  UserPreferenceInput,
} from "./types";

import { VASPUpdate } from "~/endpoints/trustFramework/types";
import {
  fetcherNotabenePublicEndpoints,
  fetcherThroughAuthProxy,
  getPDFDocumentThroughAuthProxy,
  postThroughAuthProxy,
  getThroughAuthProxy,
  putThroughAuthProxy,
  patchThroughAuthProxy,
} from "~/endpoints/utils";
import useCurrentViewer from "~/hooks/useCurrentViewer";
import { VASP, VASPProfileMetrics } from "~/types/vaspid";

export function useTfSimpleVaspInfo(
  vaspDid: string | undefined | null,
  fields?: string[] | null,
  reviewerVaspDID?: string,
  showJurisdictionStatus = false,
  options: SWRConfiguration = {
    refreshInterval: 0,
  },
  includeSubsidiaries = false,
) {
  const { viewer } = useCurrentViewer();
  const userExists = viewer.did !== "noUser";

  /* gets vasp info from our public endpoint*/
  const shouldFetch = vaspDid ? true : false;

  let tfVaspGetUrl = `tf/simple/vasps/${vaspDid}`;

  if (fields) {
    tfVaspGetUrl = `${tfVaspGetUrl}?${new URLSearchParams(({ fields } as unknown) as URLSearchParams).toString()}`;
    tfVaspGetUrl = reviewerVaspDID ? `${tfVaspGetUrl}&reviewedByVaspDID=${reviewerVaspDID}` : tfVaspGetUrl;
    tfVaspGetUrl = showJurisdictionStatus
      ? `${tfVaspGetUrl}&showJurisdictionStatus=${showJurisdictionStatus}`
      : tfVaspGetUrl;
    tfVaspGetUrl = includeSubsidiaries ? `${tfVaspGetUrl}&includeSubsidiaries=${includeSubsidiaries}` : tfVaspGetUrl;
  }

  const { data, error, isLoading, mutate } = useSWR<VASP>(
    shouldFetch ? tfVaspGetUrl : null,
    userExists ? fetcherThroughAuthProxy : fetcherNotabenePublicEndpoints,
    options,
  );

  return {
    data,
    mutate,
    isLoading: !error && isLoading,
    error,
  };
}

export function useTfProfileMetrics(vaspDid: string | undefined | null) {
  const { viewer } = useCurrentViewer();
  const userExists = viewer.did !== "noUser";

  /* gets vasp info from our public endpoint*/
  const shouldFetch = vaspDid ? true : false;

  const tfProfileMetricsUrl = `tf/vasps/${vaspDid}/profile`;

  const { data, error, isLoading, mutate } = useSWR<VASPProfileMetrics>(
    shouldFetch ? tfProfileMetricsUrl : null,
    userExists ? fetcherThroughAuthProxy : fetcherNotabenePublicEndpoints,
  );

  return {
    data,
    mutate,
    isLoading: !error && isLoading,
    error,
  };
}

export function useTfSimpleVaspList(params: SimpleListVASPInput | null, useAuth?: boolean) {
  let tfVaspListUrl = `tf/simple/vasps`;

  if (params && !_.isEmpty(params)) {
    // @ts-expect-error couldn't make ts happy with the URLSearchParams
    tfVaspListUrl = `${tfVaspListUrl}?${new URLSearchParams(params).toString()}`;
  }

  const shouldFetch = params ? true : false;

  type SimpleListVASPResponse = {
    vasps: VASP[];
    pagination: {
      page: number;
      per_page: number;
      total: number;
      order: string;
    };
  };
  const { data, error, mutate } = useSWR<SimpleListVASPResponse>(
    shouldFetch ? tfVaspListUrl : null,
    useAuth ? fetcherThroughAuthProxy : fetcherNotabenePublicEndpoints,
    {
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading: shouldFetch ? !error && !data : false,
    error,
  };
}

/*
 * Returns list of claimable, joinable and loginable VASPs for the user
 */
export function useTfUserVaspList<UserVASPListResponse>(params: UserVASPListInput | null) {
  // @ts-expect-error couldn't make ts happy with the URLSearchParams
  const urlParams = new URLSearchParams(params).toString();
  const tfVaspListUrl = `tf/vasps/userVASPList?${urlParams}`;

  const shouldFetch = params ? true : false;

  const { data, error, mutate } = useSWR<UserVASPListResponse>(
    shouldFetch ? tfVaspListUrl : null,
    fetcherThroughAuthProxy,
    {
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading: shouldFetch ? !error && !data : false,
    error,
  };
}

export const useUploadUrl = (vaspDid: string | null, documentType?: string) => {
  /*
   * Fetches the S3 bucket upload url.
   */

  const baseUploadUrl = `tf/vasps/upload/${vaspDid}`;

  const uploadUrlWithQuery = `${baseUploadUrl}?documentType=${documentType}`;

  const uploadUrl = documentType ? uploadUrlWithQuery : baseUploadUrl;

  const shouldFetch = vaspDid ? true : false;

  const { data, error, mutate } = useSWR<{ uploadUrl: string }>(
    shouldFetch ? uploadUrl : null,
    fetcherThroughAuthProxy,
    {
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading: shouldFetch ? !error && !data : false,
    error,
  };
};

export const downloadS3PDFDocument = async (vaspDid: string, key: string, documentName: string) => {
  const tfDownloadS3DocumentURL = `tf/vasps/download/${vaspDid}?s3URL=${key}`;

  await getPDFDocumentThroughAuthProxy(tfDownloadS3DocumentURL)
    .then((response) => response)
    .then((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute("download", documentName);
      link.click();
    });
};

export const deleteS3PDFDocument = async (vaspDid: string, key: string) => {
  const tfDeleteS3DocumentURL = `tf/vasps/document/delete/${vaspDid}?s3URL=${key}`;

  await postThroughAuthProxy(tfDeleteS3DocumentURL);
};

export const postTfVaspInfoUpdate = async (payload: VASPUpdate): Promise<VASPUpdate> => {
  const tfUpdateURL = `tf/vasps/update`;

  return await postThroughAuthProxy(tfUpdateURL, { payload });
};

export const postTfVaspDueDiligengeReview = async (
  reviewerVaspDID: string,
  payload: VASPDueDiligengeReviewPayload,
): Promise<VASPDueDiligengeReviewResponse> => {
  const tfVaspDueDiligengeReviewURL = `tf/vasps/${reviewerVaspDID}/review`;

  return await postThroughAuthProxy(tfVaspDueDiligengeReviewURL, { payload });
};

/*posts the vaspnet information*/
export const postTfGleifUpdate = async (payload: VASPUpdate): Promise<VASPUpdate> => {
  const tfGleifUpdateURL = `/tf/vasps/gleif/update`;

  return await postThroughAuthProxy(tfGleifUpdateURL, { payload });
};

/*posts the vaspnet information*/
export const postTfVASPNetUpdate = async (payload: VASPUpdate): Promise<VASPUpdate> => {
  const tfVASPnetUpdateURL = `/tf/vasps/vaspnet/update`;

  return await postThroughAuthProxy(tfVASPnetUpdateURL, { payload });
};

export const performDocumentAccessQuery = async (vaspDID: string, params: DocumentRequestInput) => {
  const urlParams = new URLSearchParams(params).toString();
  const tfRequestDocumentAccessURL = `tf/vasps/${vaspDID}/documents/requests?${urlParams}`;
  return await postThroughAuthProxy(tfRequestDocumentAccessURL);
};

export const useDocumentRequests = (ownerVASPdid: string | undefined, requestingVASPdid?: string | undefined) => {
  const shouldFetch = !!ownerVASPdid;

  const tfListDocumentRequestsURL =
    `tf/vasps/${ownerVASPdid}/documents/requests` +
    (requestingVASPdid ? `?requestingVASPdid=${requestingVASPdid}` : "");

  const { data, error, mutate } = useSWR<DocumentRequest[]>(
    shouldFetch ? tfListDocumentRequestsURL : null,
    fetcherThroughAuthProxy,
  );

  return {
    data,
    mutate,
    isLoading: !error && !data,
    error,
  };
};

export const tfUploadVASPDocument = async (vaspDID: string): Promise<TfUploadVASPDocumentResponse> => {
  const tfUploadVASPDocumentUrl = `tf/vasps/upload/${vaspDID}`;

  const response = await getThroughAuthProxy(tfUploadVASPDocumentUrl);
  return response;
};

export const useDDQDocument = (ownerVASPdid: string | undefined, requestingVASPdid: string | undefined) => {
  const shouldFetch = ownerVASPdid ? true : false;
  const url =
    `tf/vasps/${ownerVASPdid}/documents/ddq` + (requestingVASPdid ? `?requestingVASPdid=${requestingVASPdid}` : "");

  const { data, error, mutate } = useSWR<DDQDocument>(shouldFetch ? url : null, fetcherThroughAuthProxy, {
    refreshInterval: 5000,
  });

  return {
    data,
    mutate,
    isLoading: !error && !data,
    error,
  };
};

/*
 * Returns vasp settings
 */
export function useGetVaspSettings(vaspDID: string | null) {
  const tfGetVaspSettingURL = `tf/vasps/${vaspDID}/getSettings`;
  const shouldFetch = vaspDID ? true : false;

  const { data, error, mutate } = useSWR<VaspSettingsPayload>(
    shouldFetch ? tfGetVaspSettingURL : null,
    fetcherThroughAuthProxy,
    {
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading: shouldFetch ? !error && !data : false,
    error,
  };
}

/*
 * Returns vasp top20 counterparties data
 */
export function useGetVaspCounterparties(vaspDID: string | null) {
  const tfGetVaspCounterpartiesURL = `tf/vasps/top-counterparties?vaspDID=${vaspDID}`;
  const shouldFetch = vaspDID ? true : false;

  const { data, error, mutate } = useSWR<VaspTopCounterPartiesResponse>(
    shouldFetch ? tfGetVaspCounterpartiesURL : null,
    fetcherThroughAuthProxy,
    {
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading: shouldFetch ? !error && !data : false,
    error,
  };
}

/*
 * Updates vasp settings (PUT)
 */
export const saveVaspSettings = async (params: SaveVaspSettingsInput): Promise<VaspSettingsPayload> => {
  const tfUpdateVaspSettingURL = `tf/vasps/${params.vaspDID}/settings`;

  const payload: VaspSettingsPayload = {
    statusToProcessBlockchain: params.statusToProcessBlockchain,
    vaspDiscoverability: params.vaspDiscoverability,
  };
  return await putThroughAuthProxy(tfUpdateVaspSettingURL, { payload });
};

export function useGleifSearchOnboarding(
  leiNumber: string,
  options: SWRConfiguration = {
    refreshInterval: 0,
  },
) {
  const tfGleifSearchUrl = `tf/vasps/gleif/search?LEI=${leiNumber}&convertToSimpleVASP=true`;

  const { data, error, mutate } = useSWR<Partial<VASP>>(tfGleifSearchUrl, fetcherThroughAuthProxy, options);

  return {
    data,
    mutate,
    isLoading: !error && !data,
    error,
  };
}

export function useVASPnetSearchOnboarding(
  vaspName: string,
  vaspJurisdiction: string,
  options: SWRConfiguration = {
    refreshInterval: 0,
  },
) {
  const tfVASPnetSearchUrl = `tf/vasps/vaspnet/search?vaspName=${vaspName}&countryCode=${vaspJurisdiction}`;
  const { data, error, mutate } = useSWR<VaspnetSearchResponse>(tfVASPnetSearchUrl, fetcherThroughAuthProxy, options);

  return {
    data,
    mutate,
    isLoading: !error && !data,
    error,
  };
}

/**
 *
 * @param params Activate / deactivate VASP's subscription
 * @returns
 */
export const patchEntityEnablement = async (params: PatchEntityEnablementParams): Promise<void> => {
  const url = `/api/v1/vasps/${params.did}?entity_enablement=${params.action}`;

  return await patchThroughAuthProxy(url);
};

/**
 * Nest / Un-nest Jurisdiction
 * @param {Object} params - Request parameters
 * @param {string} params.groupVasp - Parent VASP DID
 * @param {string[]} params.subsidiaries - List of subsidiaries to nest / unnest
 * @param {string} params.action - Nest / Un-nest action
 * @returns
 */
export const patchManageSubsidiaries = async (params: PatchManageSubsidiariesParams): Promise<void> => {
  const { action, groupVaspDid, subsidiaries } = params;
  const url = `/api/v1/vasps/subsidiaries?action=${action}`;

  return await patchThroughAuthProxy(url, { group_vasp: groupVaspDid, subsidiaries });
};

/**
 * Get the actions items for the VASP
 * @param {string} vaspDid - VASP DID
 * @param {(string|undefined)} since - Date range for the actions, defaults to 1 month ago if not provided
 * @returns - Actions items
 */
export const useGetVaspActionItems = (vaspDid: string, since?: string) => {
  const shouldFetch = Boolean(vaspDid);
  const url = `tf/vasps/${vaspDid}/action-items${since ? `?since=${since}` : ""}`;

  const { data, error, mutate } = useSWR<VaspActionItems>(shouldFetch ? url : null, fetcherThroughAuthProxy, {
    refreshInterval: 0,
  });

  return {
    data,
    mutate,
    isLoading: !error && !data,
    error,
  };
};

/**
 * Get user preferences
 * @param {string[]} preferences - List of preferences to fetch
 * @returns - User preferences
 */
export const useGetUserPreferences = (preferences: string[], vaspDID?: string) => {
  const queryString = preferences.map((pref) => `preference=${pref}`).join("&");
  const url = `tf/user/preferences?${queryString}${vaspDID ? `&vasp_did=${vaspDID}` : ""}`;

  const { data, error, mutate } = useSWR<UserPreferenceResponse[]>(url, fetcherThroughAuthProxy, {
    refreshInterval: 0,
  });

  return {
    data,
    mutate,
    isLoading: !error && !data,
    error,
  };
};

/**
 * Create user preference
 * @param {Object} payload - User preference
 * @param {string} payload.preference - Preference to set
 * @param {boolean} payload.is_enabled - Is preference enabled
 * @param {string} payload.vasp_did - VASP DID
 * @returns - Created user preference
 */
export const createUserPreference = async (payload: UserPreferenceInput) => {
  const createUserPreferencesUrl = `tf/user/preferences`;
  return await postThroughAuthProxy(createUserPreferencesUrl, { payload });
};

/**
 * Update user preference
 * @param {string} id - User preference ID
 * @param {Object} payload - User preference
 * @param {string} payload.preference - Preference to set
 * @param {boolean} payload.is_enabled - Is preference enabled
 * @param {string} payload.vasp_did - VASP DID
 * @returns - Updated user preference
 */
export const updateUserPreference = async (id: string, payload: UserPreferenceInput) => {
  const updateUserPreferencesUrl = `/api/tf/user/preferences/${id}`;
  return await patchThroughAuthProxy(updateUserPreferencesUrl, { ...payload });
};
