import _ from "lodash";
import getConfig from "next/config";

import {
  ConfigCredentialsInput,
  HasCredentialsInput,
  DeleteCredentialsInput,
  AddressInfoSearchInput,
  SanctionSearchInput,
} from "../integrations";
import { SubscriptionCreateRequest, SubscriptionUpdateRequest, UserPreferenceCreateRequest } from "../payment/types";
import { TrackEventPayload } from "../tracking";
import { VASPDueDiligengeReviewPayload, VaspSettingsPayload, VASPUpdate } from "../trustFramework/types";

import {
  AddUserToVaspInput,
  AuthNewCustomerInput,
  AuthUserInfoUpdateInput,
  RemoveUserToVaspInput,
  VaspCredentialsPayload,
} from "~/endpoints/auth/types";
import { HubspotRequest } from "~/endpoints/integrations/hubspot/types";
import * as txEndpointTypes from "~/endpoints/transactions/types";
import { Issuer } from "~/types/vaspid";
const { publicRuntimeConfig } = getConfig();

export const AUTH_PROXY_API_ROUTE_URL = `/api`;
export const AUTH_PDF_PROXY_API_ROUTE_URL = `${AUTH_PROXY_API_ROUTE_URL}/pdf`;
export const AUTH_NOTABENE_PROXY_API_ROUTE_URL = `${AUTH_PROXY_API_ROUTE_URL}/notabeneAuth`;
export const NOTABENE_PUBLIC_PROXY_API_ROUTE_URL = `${AUTH_PROXY_API_ROUTE_URL}/notabenePublic`;
export const METABASE_DASHBOARD_API_URL = `${AUTH_PROXY_API_ROUTE_URL}/metabase`;

export type PostPayloadTypes =
  | txEndpointTypes.TransactionCreateRequest
  | txEndpointTypes.TransactionRejectRequest
  | txEndpointTypes.TransactionDeclineRequest
  | txEndpointTypes.TransactionCreateRequest
  | txEndpointTypes.TransactionValidateRequest
  | txEndpointTypes.TransactionBulkRequest
  | txEndpointTypes.TransactionExportRequestCreateRequest
  | txEndpointTypes.TransactionBulkRedirectRequest
  | VASPUpdate
  | AddUserToVaspInput
  | RemoveUserToVaspInput
  | AuthNewCustomerInput
  | AuthUserInfoUpdateInput
  | HubspotRequest
  | ConfigCredentialsInput
  | HasCredentialsInput
  | DeleteCredentialsInput
  | AddressInfoSearchInput
  | SanctionSearchInput
  | VaspSettingsPayload
  | VaspCredentialsPayload
  | VASPDueDiligengeReviewPayload
  | TrackEventPayload
  | Issuer
  | SubscriptionCreateRequest
  | SubscriptionUpdateRequest
  | UserPreferenceCreateRequest;

export type FetcherOptions = {
  payload?: PostPayloadTypes;
  requestMethod?: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
};

export const useSegmentWriteKey = () => publicRuntimeConfig.segment.key;

export const postThroughAuthProxy = async (url: string, options?: FetcherOptions) => {
  const authedURL = `${AUTH_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const rawResponse = await fetch(authedURL, {
    method: "POST",
    ...(options?.payload && { body: JSON.stringify(options.payload), headers }),
  });

  const response = await rawResponse.json();
  validateResponseStatus(rawResponse.status, response?.err?.message || response?.error?.name);
  return response;
};

/**
 * Redirects the provided endpoint to go through the Authentication API Route (which holds the accessToken)
 */
export const fetcherThroughAuthProxy = async (url: string, options?: FetcherOptions) => {
  const authedURL = `${AUTH_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const response = await fetch(authedURL, {
    method: options?.requestMethod || "GET",
    ...(options?.payload && { body: JSON.stringify(options.payload), headers }),
  });

  await validateResponseStatusForUseSWR(response);

  return await response.json();
};

/**
 * Redirects the provided endpoint to go through the Authentication API Route (which holds the accessToken)
 */
export const fileFetcherThroughAuthProxy = async (url: string, options?: FetcherOptions) => {
  const authedURL = `${AUTH_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const response = await fetch(authedURL, {
    method: options?.requestMethod || "GET",
    ...(options?.payload && {
      body: JSON.stringify(options.payload),
      headers,
    }),
  });

  await validateResponseStatusForUseSWR(response);

  return response;
};

/**
 * Use the Notabene Auth Api Route to fetch
 */
export const fetcherThroughAuthProxyForNotabeneAuth = async (url: string, options?: FetcherOptions) => {
  const authedURL = `${AUTH_NOTABENE_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const response = await fetch(authedURL, {
    method: options?.requestMethod || "GET",
    ...(options?.payload && { body: JSON.stringify(options.payload), headers }),
  });

  await validateResponseStatusForUseSWR(response);

  return await response.json();
};

/**
 * Use the Notabene Api Route to fetch public notabene endpoints (no accessToken)
 */
export const fetcherNotabenePublicEndpoints = async (url: string) => {
  const nonAuthedURL = `${NOTABENE_PUBLIC_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const response = await fetch(nonAuthedURL, { headers });

  await validateResponseStatusForUseSWR(response);

  return await response.json();
};

const validateResponseStatusForUseSWR = async (response: Response) => {
  /*
   NOTE: We could improve this w, I'm not sure if we need to throw when using useSWR 🤷🏼‍♂️ [Lluis Suros]
   At the moment, we toast a message and raise an error. useSWR should return the error.
  */
  // If the status code is not in the range 200-299, we still try to parse and throw it.
  if (!response.ok) {
    const errorInfo = await response.text();
    const errorMessage = `(code ${response.status}) An error occurred while fetching the data. Error message: ${errorInfo}`;
    const error = new Error(errorMessage);
    try {
      const jsonResponse = JSON.parse(errorInfo);
      error.cause = { jsonResponse };
    } catch (err) {
      error.cause = { parseError: err };
    }
    throw error;
  }
};

const validateResponseStatus = (status: number, errorMessage?: string) => {
  /*
   NOTE: this error handling is too simple at the moment 🤷🏼‍♂️ [Lluis Suros]
   At the moment, we only throw if error.
  */
  const isValid = status >= 200 && status <= 299;
  if (!isValid) {
    throw new Error(`error code ${status}: ${errorMessage}`, {
      cause: {
        status,
        message: errorMessage,
      },
    });
  }
};

export const getThroughAuthProxy = async (url: string) => {
  const authedURL = `${AUTH_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const rawResponse = await fetch(authedURL, {
    method: "GET",
    headers,
  });

  const response = await rawResponse.json();
  validateResponseStatus(rawResponse.status, response?.err?.message || response?.error?.name);
  return response;
};

export const deleteThroughAuthProxy = async (url: string) => {
  const authedURL = `${AUTH_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const rawResponse = await fetch(authedURL, {
    method: "DELETE",
    headers,
  });

  const response = await rawResponse.json();
  validateResponseStatus(rawResponse.status, response?.err?.message || response?.error?.name);
  return response;
};

export const getWithoutToken = async (url: string) => {
  /** creates a get request with the Access Token (NOTE: think about using useSWR instead) */

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-User-Agent", "notabene/dashboard");

  const rawResponse = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  const response = await rawResponse.json();

  validateResponseStatus(rawResponse.status, response?.err?.message || response?.error?.name);
  return response;
};

/** creates a get request to obtain a PDF document with the Access Token*/
export const getPDFDocumentThroughAuthProxy = async (url: string) => {
  const authedPdfURL = `${AUTH_PDF_PROXY_API_ROUTE_URL}/${url}`;

  const rawResponse = await fetch(authedPdfURL, {
    method: "GET",
  });

  const response = await rawResponse.blob();
  validateResponseStatus(rawResponse.status, rawResponse.statusText);
  return response;
};

export const patchThroughAuthProxy = async (url: string, body?: Record<string, unknown>) => {
  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const rawResponse = await fetch(url, {
    method: "PATCH",
    headers,
    ...(_.isEmpty(body) ? {} : { body: JSON.stringify(body) }),
  });

  if (rawResponse.status !== 204) {
    const response = await rawResponse.json();

    validateResponseStatus(rawResponse.status, response?.err?.message || response?.error?.name);
    return response;
  }
};

export const putThroughAuthProxy = async (url: string, options?: FetcherOptions) => {
  const authedURL = `${AUTH_PROXY_API_ROUTE_URL}/${url}`;

  const headers = new Headers();
  headers.append("X-User-Agent", "notabene/dashboard");

  const rawResponse = await fetch(authedURL, {
    method: "PUT",
    ...(options?.payload && { body: JSON.stringify(options.payload), headers }),
  });

  const response = await rawResponse.json();
  validateResponseStatus(rawResponse.status, response?.err?.message || response?.error?.name);
  return response;
};

export const postWithoutToken = async (url: string, options?: FetcherOptions) => {
  /** creates a post request without the Access Token */
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-User-Agent", "notabene/dashboard");

  const rawResponse = await fetch(url, {
    method: "POST",
    headers: headers,
    ...(options?.payload && { body: JSON.stringify(options.payload) }),
  });

  const response = await rawResponse.json();
  validateResponseStatus(rawResponse.status, response?.err?.message || response?.error?.name);
  return response;
};

export const getMetabaseIFrameURL = async (options?: FetcherOptions) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-User-Agent", "notabene/dashboard");

  const metabaseURL = `${METABASE_DASHBOARD_API_URL}/get-iframe-url`;

  const rawResponse = await fetch(metabaseURL, {
    method: "POST",
    headers: headers,
    ...(options?.payload && { body: JSON.stringify(options.payload) }),
  });

  const response = await rawResponse.json();
  validateResponseStatus(rawResponse.status, rawResponse.statusText);
  return response;
};
