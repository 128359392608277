import { createContext, useEffect, useRef } from "react";

import { useLDClient } from "launchdarkly-react-client-sdk";
import router from "next/router";

import { CompanyWizardState } from "./types";

import Loading from "~/components/Loading";
import { useAuthViewer } from "~/endpoints/auth";
import { AuthViewerResponse } from "~/endpoints/auth/types";
import { initDatadogRumUserSession } from "~/utils/dd-rum";

type ViewerContextType = {
  viewer: AuthViewerResponse & {
    getCompanyWizardState: () => CompanyWizardState;
    setCompanyWizardState: (companyWizardState: CompanyWizardState) => void;
    resetCompanyWizardState: () => void;
  };
  online: boolean;
  reloadCurrentAuthViewer: () => Promise<any>;
  isLoading: boolean;
  previousPath: string;
};

const getCompanyWizardState = () => {
  return JSON.parse(localStorage.getItem("company-wizard-state") || "{}") as CompanyWizardState;
};

const setCompanyWizardState = (companyWizardState: CompanyWizardState) => {
  localStorage.setItem("company-wizard-state", JSON.stringify(companyWizardState));
};

const resetCompanyWizardState = () => {
  localStorage.removeItem("company-wizard-state");
};

export const VIEWER_INITIAL_DEFAULT_VALUE: ViewerContextType = {
  viewer: {
    did: "noUser",
    fields: [],
    vasps: [],
    roles: [
      {
        vaspDID: "initialVaspDid",
        permissions: [],
      },
    ],
    getCompanyWizardState: getCompanyWizardState,
    setCompanyWizardState: setCompanyWizardState,
    resetCompanyWizardState: resetCompanyWizardState,
  },
  online: false,
  reloadCurrentAuthViewer: async () => {
    /**do nothing */
  },
  isLoading: true,
  previousPath: "",
};

//we keep the export of the ViewerContext for some unit tests, but prefere ViewerContextProvider
export const ViewerContext = createContext<ViewerContextType>(VIEWER_INITIAL_DEFAULT_VALUE);

export function ViewerContextProvider(props: { children: React.ReactNode }) {
  const { data: authViewerData, mutate: reloadCurrentAuthViewer, isLoading, online } = useAuthViewer();
  const viewerProps =
    online && authViewerData ? (authViewerData as AuthViewerResponse) : VIEWER_INITIAL_DEFAULT_VALUE.viewer;
  const previousPath = useRef<string>("");

  const viewerContextValue: ViewerContextType = {
    viewer: {
      ...viewerProps,
      getCompanyWizardState: getCompanyWizardState,
      setCompanyWizardState: setCompanyWizardState,
      resetCompanyWizardState: resetCompanyWizardState,
    },
    online,
    reloadCurrentAuthViewer,
    isLoading,
    previousPath: previousPath.current,
  };

  const ldClient = useLDClient();
  useEffect(() => {
    if (!ldClient) {
      return;
    }
    ldClient.identify({ key: authViewerData?.did ?? "anonymous" });
  }, [ldClient, authViewerData]);

  useEffect(() => {
    //set user session for datadog RUM
    if (!authViewerData || !authViewerData.did || authViewerData.did === "noUser") {
      return;
    }
    initDatadogRumUserSession({ userDid: authViewerData.did });
  }, [authViewerData]);

  useEffect(() => {
    const handleRouteChange = () => {
      previousPath.current = router.asPath;
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return <ViewerContext.Provider value={viewerContextValue}>{props.children}</ViewerContext.Provider>;
}
